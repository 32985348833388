.bg-video-container {
  width: 100%;
}

.bg-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  top: 0;
}

.category {
  padding: 8px;
  /* border-bottom: 1px solid blue; */
}

.icon-img {
  width: 50px;
  align-items: center;
}

@media only screen and (max-width: 380px) {
  .icon-img {
    width: 100px;
  }
}

.about-icon {
  width: 80px;
}

@media only screen and (max-width: 788px) {
  .about-icon {
    width: 60px;
  }
}

.customization-content-ltr {
  flex-direction: row-reverse;
}

.hero-text {
  display: inline-block;
  color: #ecf8f9;
  background-color: blue;
  display: inline-flex;
  padding: 3px;
  /* font-size: 24px; */
  background-color: #a1e1e75f;
  color: #fff;

  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 212, 255, 0.08727240896358546) 0%,
    rgba(60, 68, 207, 0.053658963585434205) 100%
  );
}

.custom-h2 {
  font-size: 20px;
}
